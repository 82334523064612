<template>
  <b-modal
    id="counter-parties-modal"
    centered
    :title="isEdit ? 'Редактировать ' : 'Добавить'"
    no-close-on-backdrop
    @show="openModal"
  >
    <validation-observer ref="PayersModal">
      <b-form-group label="Контрагенты: " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="контрагенты "
          rules="required"
        >
          <b-form-input
            v-model="modalValue.title"
            placeholder="Введите контрагенты "
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label="ИНН : " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="инн"
          rules="required|integer"
        >
          <b-form-input
            v-model="modalValue.inn"
            placeholder="Введите инн"
            maxlength="14"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <div>
      <b-form-checkbox
        class="custom-control-primary"
        name="check-button"
        id="checkbox-1"
        switch
        v-model="modalValue.is_active"
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </b-form-checkbox>
    </div>

    <div slot="modal-footer">
      <b-button
        class="mx-1"
        variant="danger"
        @click="$bvModal.hide('counter-parties-modal')"
      >
        {{ $t('client.cancel') }}
      </b-button>
      <b-button :variant="isEdit ? 'warning' : 'success'" @click="onSubmit">
        {{ isEdit ? $t('change') : $t('save') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required, integer, length } from '@validations'
import { mapActions } from 'vuex'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },

  data() {
    return {
      integer,
      length,
      id: '',
      modalValue: {
        title: '',
        is_active: true,
        inn: '',
      },
      required,
    }
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },

    docTypeData: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },

  methods: {
    ...mapActions('knowledgeBase', ['CREATE_CONTRACTOR', 'UPDATE_CONTRACTOR']),

    openModal() {
      if (!this.isEdit) {
        this.modalValue.title = ''
        this.modalValue.is_active = true
        this.modalValue.inn = ''
      } else {
        this.modalValue.title = this.docTypeData.title
        this.modalValue.is_active = this.docTypeData.is_active
        this.modalValue.inn = this.docTypeData.inn
      }
    },

    async onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      const res = await this.$refs.PayersModal.validate()

      if (!res) {
        return
      }

      try {
        let ACTION = this.isEdit ? this.UPDATE_CONTRACTOR : this.CREATE_CONTRACTOR

        await ACTION({
          data: this.modalValue,
          id: this.docTypeData.id,
        })
        this.$emit('refresh')

        this.$nextTick(() => {
          this.$_successToast(this.$t('general.success'))
          this.$bvModal.hide('counter-parties-modal')
        })
      } catch (error) {
        // console.log(error.response.data?.inn?.[0])
        console.log('error', error)
        this.$_errorToast("Контрагенты с этой гостиницей уже существует.")
      }
    },
  },
}
</script>

<style scoped>
#username {
  text-transform: uppercase;
}
</style>
