<template>
  <div>
    <div
      class="custom-search d-flex align-items-center justify-content-between"
    >
      <div class="custom-search">
        <AppSearchInput @callback="search" :placeholder="$t('search')" />
      </div>

      <b-button
        variant="primary"
        @click="addData"
        v-b-modal.counter-parties-modal
        class="mt-2"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('add') }}
      </b-button>
    </div>

    <b-table
      :busy="busy"
      id="user-table"
      :fields="fields"
      :items="GET_CONTRACTORS.results"
      :tbody-tr-class="['tableRowClass']"
      show-empty
      :empty-text="$t('administration.no_records')"
      small
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #empty>
        <h4 class="text-center">{{ $t('administration.no_records') }}</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4 class="text-center">{{ scope.emptyFilteredText }}</h4>
      </template>
      <template #head()="props">
        <span>{{ $t(props.label) }}</span>
      </template>

      <template #cell(is_active)="row" class="text-center">
        <b-form-checkbox
          :checked="row.item.is_active"
          name="check-button"
          class="custom-control-primary"
          switch
          inline
          disabled
        >
        </b-form-checkbox>
      </template>

      <template #cell(actions)="{ item }">
        <div class="d-flex justify-content-end">
          <b-button
            v-b-modal.counter-parties-modal
            variant="warning"
            size="sm"
            class="mr-1"
            @click="editData(item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button size="sm" variant="danger" @click="deleteItem(item.id)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>

    <b-card>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap">{{ $t('pagination.show_from') }}</span>
          <b-form-select
            v-model="params.page_size"
            :options="['5', '10', '15']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="text-nowrap"> {{ $t('pagination.lines') }} </span>
        </div>

        <b-pagination
          v-model="params.page"
          :total-rows="GET_CONTRACTORS.count"
          :per-page="params.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @change="changePage"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>

    <edit-modal-overlay
      :is-edit="modalType"
      :doc-type-data="doc_type_data"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BButton,
  BFormSelect,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import EditModalOverlay from './components/EditModal.vue'
import { mapActions, mapGetters } from 'vuex'
import AppSearchInput from '@/components/AppSearchInput.vue'
import areYouSure from '@/mixins/areYouSure'

export default {
  components: {
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    EditModalOverlay,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    AppSearchInput,
    BCard,
    BSpinner,
  },

  mixins: [areYouSure],
  data() {
    return {
      modalType: false,
      doc_type_data: {},
      params: {
        page: 1,
        page_size: 10,
        search: '',
      },
      busy: false,

      fields: [
        {
          label: 'Плательщик',
          key: 'title',
          thClass: 'tableHeadClass',
        },
        {
          label: 'ИНН ',
          key: 'inn',
          thClass: 'tableHeadClass',
        },
        {
          label: this.$t('client.activity'),
          key: 'is_active',
          thClass: 'tableHeadClass',
        },
        {
          label: this.$t('actions'),
          key: 'actions',
          thClass: 'tableHeadClass',
          // tdClass: 'width-150',
        },
      ],
      initTimeout: null,
    }
  },

  computed: {
    ...mapGetters('knowledgeBase', ['GET_CONTRACTORS']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('knowledgeBase', ['FETCH_CONTRACTORS', 'DELETE_CONTRACTOR']),

    async refresh() {
      try {
        this.busy = true

        await this.FETCH_CONTRACTORS(this.params)
      } catch (error) {
        this.$_errorToast(this.$t('error'))
      }

      this.busy = false
    },

    search(text) {
      this.params.search = text
      this.params.page = 1
      this.refresh()
    },

    changePage(value) {
      this.params.page = value
      this.refresh()
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.refresh()
    },

    editData(item) {
      this.modalType = true
      this.doc_type_data = item
    },
    addData() {
      this.modalType = false
    },

    deleteItem(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          if (this.GET_CONTRACTORS.results.length === 1) {
            if (this.params.page > 1) --this.params.page
          }
          this.DELETE_CONTRACTOR(id)
            .then(() => {
              this.refresh()
              this.$_successToast(this.$t('general.success'))
              this.params.page = 1
            })
            .catch(() => {
              this.$_errorToast(this.$t('error'))
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
// .vs__open-indicator {
//     fill: rgba(60, 60, 60, 0.5) !important;
//     transition: transform 150ms cubic-bezier(1, -0.115, 0.975, 0.855);
// }

.table-b-table-default {
  &:nth-child(2) {
    text-align: right !important;
  }
}

#user-table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

#user-table .tableRowClass {
  overflow: hidden;
  background: white;
  transform: translateY(5px);
  transition: all 0.5s ease-in-out;
  text-align: left !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  // -webkit-box-shadow:  0 4px 20px 0px lightgrey;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  // -moz-box-shadow:     0 4px 20px 0px lightgrey;  /* Firefox 3.5 - 3.6 */
  // box-shadow:          0 4px 6px 0px lightgrey;
  td {
    border-top: 0;
    padding: 20px !important;

    &:first-child {
      // padding-left: 25px !important;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

#user-table .tableRowClass:hover {
  transform: translateY(0);
  // -webkit-box-shadow:  0 4px 20px 0px lightgrey;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  // -moz-box-shadow:     0 4px 20px 0px lightgrey;  /* Firefox 3.5 - 3.6 */
  // box-shadow:          0 4px 6px 0px lightgrey;
}

// .b-table-sticky-header {
//   overflow-y: auto;
// }
#user-table .tableHeadClass {
  background: none !important;
  border: none !important;

  text-align: left !important;
  // padding-left: 25px !important;
  cursor: pointer;
}

#user-table .tableRowClass:hover {
  .tableTitle {
    color: #7367f0 !important;
  }
}

.button-mt {
  margin-top: 25px;
}
</style>
